var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-card',{staticClass:"card-normal",class:_vm.checkDateExp(_vm.data.insuranceEnd) == true ? 'close' : '',attrs:{"shadow":"never"}},[_c('div',{staticClass:"box-img"},[(_vm.data.image != '')?_c('img',{staticClass:"img-content cursor-pointer",attrs:{"src":_vm.imgUrlTERAK + _vm.data.image,"alt":""},on:{"click":function($event){return _vm.$emit('morePicture', _vm.imgUrlTERAK + _vm.data.image)}}}):_c('img',{staticClass:"img-content",attrs:{"src":require(`@/assets/image-default.jpg`),"alt":""}}),(_vm.checkDateExp(_vm.data.insuranceEnd) == true)?_c('div',{staticClass:"time-out"},[_c('span',[_c('i',{staticClass:"fas fa-calendar-alt mg-r-7"}),_vm._v("สิ้นสุดเวลาประกัน เมื่อ "+_vm._s(_vm._f("formatDateTHNumber")(_vm.data.insuranceEnd)))])]):_vm._e()]),_c('div',{staticClass:"detail-card"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.data.insuranceName,"placement":"bottom-start"}},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.data.insuranceName))])]),(_vm.pageNow != 'project')?_c('div',{staticClass:"bussiness-type credit font-weight-600"},[_c('div',[_vm._v(" ขนาดธุรกิจ: "),(_vm.data.sendToCH == true)?_c('el-tooltip',{staticClass:"item font-22",attrs:{"effect":"dark","content":"ถูกนำเสนอประกันการส่งออก ไปยังระบบ Channel Integration แล้ว","placement":"bottom-start"}},[_c('span',[(_vm.data.insuranceSizeS)?_c('span',[_vm._v("SIZE S ")]):_vm._e(),(
                (_vm.data.insuranceSizeS && _vm.data.insuranceSizeM) ||
                (_vm.data.insuranceSizeS && _vm.data.insuranceSizeL)
              )?_c('span',[_vm._v(", ")]):_vm._e(),(_vm.data.insuranceSizeM)?_c('span',[_vm._v("SIZE M ")]):_vm._e(),(_vm.data.insuranceSizeM && _vm.data.insuranceSizeL)?_c('span',[_vm._v(", ")]):_vm._e(),(_vm.data.insuranceSizeL)?_c('span',[_vm._v("SIZE L ")]):_vm._e()])]):_c('span',[(_vm.data.insuranceSizeS)?_c('span',[_vm._v("SIZE S ")]):_vm._e(),(
              (_vm.data.insuranceSizeS && _vm.data.insuranceSizeM) ||
              (_vm.data.insuranceSizeS && _vm.data.insuranceSizeL)
            )?_c('span',[_vm._v(", ")]):_vm._e(),(_vm.data.insuranceSizeM)?_c('span',[_vm._v("SIZE M ")]):_vm._e(),(_vm.data.insuranceSizeM && _vm.data.insuranceSizeL)?_c('span',[_vm._v(", ")]):_vm._e(),(_vm.data.insuranceSizeL)?_c('span',[_vm._v("SIZE L ")]):_vm._e()])],1)]):_vm._e(),(
        _vm.data.insuranceOverview != null &&
        _vm.data.insuranceOverview != 'null' &&
        _vm.data.insuranceOverview != '' &&
        _vm.checkScriptHTML(_vm.data.insuranceOverview)
      )?_c('p',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm._f("striphtml")(_vm.data.insuranceOverview))+" ")]):_vm._e(),_c('p',{staticClass:"see-more"},[_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.goToLink(`/manage/${_vm.pageNow}/detail/${_vm.data._id}`)}}},[_c('span',[_vm._v("อ่านรายละเอียด ")])])]),_c('div',{staticClass:"text-center mobile-edit"},[(_vm.handleKeepBtn)?_c('span',{staticClass:"btn-kep-item",attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleKepItem(_vm.data._id)}}},[_c('i',{staticClass:"fa fa-archive",attrs:{"aria-hidden":"true"}}),_vm._v(" จัดเก็บ ")]):_vm._e(),(_vm.handleKeepBtn)?_c('span',{staticClass:"btn-copy-item",attrs:{"size":"mini"},on:{"click":function($event){return _vm.copyItem(_vm.data._id)}}},[_c('i',{staticClass:"fas fa-copy",attrs:{"aria-hidden":"true"}}),_vm._v(" คัดลอก ")]):_vm._e(),(_vm.permissionMore == true)?_c('router-link',{staticClass:"edit-card",class:!_vm.checkKeepByRole ? 'right' : '',attrs:{"to":`/manage/${_vm.pageNow}/edit/${_vm.data._id}`}},[_c('i',{staticClass:"fas fa-edit"}),_vm._v(" แก้ไข ")]):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }