var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"credit_keep_item"}},[_c('div',{staticClass:"animate__animated animate__fadeIn animate__slow"},[_c('div',{staticClass:"box-carousel"},[(_vm.data.length > 0)?_c('carousel',{staticClass:"carousel-slide",attrs:{"margin":15,"autoplay":false,"nav":false,"loop":false,"items":4,"dots":false,"mouseDrag":false,"responsive":_vm.optionResponsive}},[_vm._l((_vm.data),function(row,index){return _c('div',{key:index,staticClass:"mg-y-5 animate__animated animate__fadeIn animate__slow slide"},[_c('el-card',{staticClass:"card-normal keep-active",class:_vm.checkDateExp(row.insuranceEnd) == true ? 'close' : '',attrs:{"shadow":"never"}},[_c('div',{staticClass:"box-img"},[(row.image != '')?_c('img',{staticClass:"img-content cursor-pointer",attrs:{"src":_vm.imgUrlTERAK + row.image,"alt":""},on:{"click":function($event){return _vm.$emit('morePicture', _vm.imgUrlTERAK + row.image)}}}):_c('img',{staticClass:"img-content",attrs:{"src":require(`@/assets/image-default.jpg`),"alt":""}}),(_vm.checkDateExp(row.insuranceEnd) == true)?_c('div',{staticClass:"time-out"},[_c('span',[_c('i',{staticClass:"fas fa-calendar-alt mg-r-7"}),_vm._v("สิ้นสุดเวลาประกัน เมื่อ "+_vm._s(_vm._f("formatDateTHNumber")(row.insuranceEnd)))])]):_vm._e()]),_c('div',{staticClass:"detail-card"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.insuranceName,"placement":"bottom-start"}},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(row.insuranceName))])]),(_vm.pageNow != 'project')?_c('div',{staticClass:"bussiness-type credit font-weight-600"},[_c('p',[_vm._v(" ขนาดธุรกิจ: "),(row.insuranceSizeS)?_c('span',[_vm._v("SIZE S ")]):_vm._e(),(
                      (row.insuranceSizeS && row.insuranceSizeM) ||
                      (row.insuranceSizeS && row.insuranceSizeL)
                    )?_c('span',[_vm._v(", ")]):_vm._e(),(row.insuranceSizeM)?_c('span',[_vm._v("SIZE M ")]):_vm._e(),(row.insuranceSizeM && row.insuranceSizeL)?_c('span',[_vm._v(", ")]):_vm._e(),(row.insuranceSizeL)?_c('span',[_vm._v("SIZE L ")]):_vm._e()])]):_vm._e(),(
                  row.insuranceOverview != null &&
                  row.insuranceOverview != 'null' &&
                  row.insuranceOverview != '' &&
                  _vm.checkScriptHTML(row.insuranceOverview)
                )?_c('p',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm._f("striphtml")(row.insuranceOverview))+" ")]):_vm._e(),_c('p',{staticClass:"see-more"},[_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.goToLink(`/manage/${_vm.pageType}/detail/${row._id}`)}}},[_c('span',[_vm._v("อ่านรายละเอียด ")])])]),_c('div',{staticClass:"text-center mobile-edit"},[(_vm.handleKeepBtn)?_c('span',{staticClass:"btn-kep-item",attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleKeepItemCancle(row._id)}}},[_c('i',{staticClass:"fas fa-trash-restore",attrs:{"aria-hidden":"true"}}),_vm._v(" ยกเลิก ")]):_vm._e(),(_vm.handleKeepBtn)?_c('span',{staticClass:"btn-copy-item",attrs:{"size":"mini"},on:{"click":function($event){return _vm.copyItem(row._id)}}},[_c('i',{staticClass:"fas fa-copy",attrs:{"aria-hidden":"true"}}),_vm._v(" คัดลอก ")]):_vm._e(),(_vm.permissionMore == true)?_c('router-link',{staticClass:"edit-card",attrs:{"to":`/manage/${_vm.pageType}/edit/${row._id}`}},[_c('i',{staticClass:"fas fa-edit"}),_vm._v(" แก้ไข ")]):_vm._e()],1)],1)])],1)}),(_vm.data.length > 4)?_c('template',{slot:"prev"},[_c('div',{staticClass:"box-prev"},[_c('i',{staticClass:"fas fa-angle-left"})])]):_vm._e(),(_vm.data.length > 4)?_c('template',{slot:"next"},[_c('div',{staticClass:"box-next"},[_c('i',{staticClass:"fas fa-angle-right"})])]):_vm._e()],2):_c('div',{staticClass:"text-center mg-t-3 font-30 font-weight-500"},[(_vm.dataSearch != '')?_c('span',[_vm._v(" ไม่พบข้อมูลที่คุณค้นหา ")]):_c('span',[_vm._v(" ไม่มีข้อมูล ")])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }