<template>
  <el-card
    class="card-normal"
    :class="checkDateExp(data.insuranceEnd) == true ? 'close' : ''"
    shadow="never"
  >
    <div class="box-img">
      <img
        v-if="data.image != ''"
        :src="imgUrlTERAK + data.image"
        alt=""
        @click="$emit('morePicture', imgUrlTERAK + data.image)"
        class="img-content cursor-pointer"
      />
      <img
        v-else
        :src="require(`@/assets/image-default.jpg`)"
        alt=""
        class="img-content"
      />
      <div class="time-out" v-if="checkDateExp(data.insuranceEnd) == true">
        <span>
          <i class="fas fa-calendar-alt mg-r-7"></i>สิ้นสุดเวลาประกัน เมื่อ
          {{ data.insuranceEnd | formatDateTHNumber }}</span
        >
      </div>
    </div>

    <div class="detail-card">
      <el-tooltip
        class="item"
        effect="dark"
        :content="data.insuranceName"
        placement="bottom-start"
      >
        <p class="title">{{ data.insuranceName }}</p>
      </el-tooltip>
      <div
        class="bussiness-type credit font-weight-600"
        v-if="pageNow != 'project'"
      >
        <div>
          ขนาดธุรกิจ:
          <el-tooltip
            v-if="data.sendToCH == true"
            class="item font-22"
            effect="dark"
            content="ถูกนำเสนอประกันการส่งออก ไปยังระบบ Channel Integration แล้ว"
            placement="bottom-start"
          >
            <span>
              <span v-if="data.insuranceSizeS">SIZE S </span>
              <span
                v-if="
                  (data.insuranceSizeS && data.insuranceSizeM) ||
                  (data.insuranceSizeS && data.insuranceSizeL)
                "
                >,
              </span>
              <span v-if="data.insuranceSizeM">SIZE M </span>
              <span v-if="data.insuranceSizeM && data.insuranceSizeL">, </span>
              <span v-if="data.insuranceSizeL">SIZE L </span>
            </span>
          </el-tooltip>
          <span v-else>
            <span v-if="data.insuranceSizeS">SIZE S </span>
            <span
              v-if="
                (data.insuranceSizeS && data.insuranceSizeM) ||
                (data.insuranceSizeS && data.insuranceSizeL)
              "
              >,
            </span>
            <span v-if="data.insuranceSizeM">SIZE M </span>
            <span v-if="data.insuranceSizeM && data.insuranceSizeL">, </span>
            <span v-if="data.insuranceSizeL">SIZE L </span>
          </span>
        </div>
      </div>
      <p
        class="content"
        v-if="
          data.insuranceOverview != null &&
          data.insuranceOverview != 'null' &&
          data.insuranceOverview != '' &&
          checkScriptHTML(data.insuranceOverview)
        "
      >
        {{ data.insuranceOverview | striphtml }}
      </p>

      <p class="see-more">
        <span
          @click="goToLink(`/manage/${pageNow}/detail/${data._id}`)"
          class="cursor-pointer"
        >
          <span>อ่านรายละเอียด </span>
        </span>
      </p>
      <div class="text-center mobile-edit">
        <span
          v-if="handleKeepBtn"
          class="btn-kep-item"
          @click="handleKepItem(data._id)"
          size="mini"
          ><i class="fa fa-archive" aria-hidden="true"></i> จัดเก็บ
        </span>
        <span
          v-if="handleKeepBtn"
          class="btn-copy-item"
          @click="copyItem(data._id)"
          size="mini"
          ><i class="fas fa-copy" aria-hidden="true"></i> คัดลอก
        </span>
        <router-link
          :to="`/manage/${pageNow}/edit/${data._id}`"
          class="edit-card"
          :class="!checkKeepByRole ? 'right' : ''"
          v-if="permissionMore == true"
        >
          <i class="fas fa-edit"></i>
          แก้ไข
        </router-link>
      </div>
    </div>
  </el-card>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  props: [
    "data",
    "pageNow",
    "permissionMore",
    "checkKeepByRole",
    "handleKeepBtn",
    "typeData",
    "typeTable"
  ],
  data() {
    return {
      imgUrlTERAK: process.env.VUE_APP_BASE_URL_TERAK_IMAGE,
    };
  },
  methods: {
    checkScriptHTML(value) {
      const strippedString = value.replace(/(<([^>]+)>)/gi, "");
      let rep = new RegExp("&nbsp;", "g");
      let setReplace = strippedString.replaceAll(rep, "");
      if (setReplace != null && setReplace != "null" && setReplace != "") {
        return true;
      } else {
        return false;
      }
    },
    checkDateExp(val) {
      let now = new Date();
      let dateNow = moment(now).format("YYYY-MM-DD");
      let dateEnd = moment(val).format("YYYY-MM-DD");
      if (dateNow > dateEnd) {
        return true;
      } else if (dateNow == dateEnd) {
        return false;
      } else {
        return false;
      }
    },
    goToLink(data) {
      this.$router.push(data);
    },
    copyItem(id) {
      this.$emit("copyItem", id, this.typeData, this.typeTable);
    },
    handleKepItem(id) {
      this.$emit("diaLogsKepInsurance", id, this.typeData);
    },
  },
};
</script>